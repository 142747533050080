import BasePlugin from '../BasePlugin'

export default class LoadFileCorporateSecurity extends BasePlugin {
  async execute () {
    this.context.$loading()
    let me = this
    let address = `${this.context.$config.api}/files/09a5adf2-d13f-48e7-93fa-2a78789ab116.pptx`
    this.context.$http({
      method: 'get',
      url: address,
      responseType: 'arraybuffer'
    }).then((response2) => {
      this.openExportAttachment(response2.data, me, 'pptx', 'Анализ исполнения_Блок корпоративной безопасности 8 мес. 2020 г..pptx')
      this.context.$loading().close()
    })
  }
}
